import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import DrawSvg from "../DrawSvg";

const Roadmap = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];

  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    let t1 = gsap.timeline();
    revealRefs.current.forEach((el, index) => {
      t1.fromTo(
        el.childNodes[0],
        {
          y: "0",
        },
        {
          y: "-30%",
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top center+=200px",
            end: "bottom center",
            scrub: true,
          },
        }
      );
    });

    return () => {};
  }, []);

  return (
    <Section id="roadmap">
      <Title>Наши технологии</Title>
      <Container>
        <SvgContainer>
          <DrawSvg />
        </SvgContainer>
        <Items>
          <Item>&nbsp;</Item>
          <RoadmapItem
            addToRefs={addToRefs}
            title="Web-стек frontend"
            subtext="JavaScript, HTML5, CSS3, Vue.js, Angular, React,
Next js, Redux, TypeScript, Mob-x, Graph ql, Svelte,
Gatsby js, Single Page Applications (SPA), LESS,
PostCSS, RequireJS, ES6 Modules, Webpack, Gulp,
Google Closure Compiler, jQuery, Backbone.js,
REST, WebSockets, SVG, Google Web Toolkit
(GWT), Bootstrap"
          />
          <RoadmapItem
            addToRefs={addToRefs}
            title="Серверная сторона, backend"
            subtext="Java SE/EE, Spring, Hibernate, Servlets/JSP, Google
Web Toolkit (GWT), Groovy/Grails, JasperReports,
REST/JSON, WebServices/SOAP, XML/XSD/DTD
Настольные приложения, desktop
JavaFX, Swing, SWT, Eclipse RCP, плагины для
Eclipse Platform, плагины для IntelliJ Platform"
          />
          <RoadmapItem
            addToRefs={addToRefs}
            title="Платформа приложений, интеграция и масштабирование"
            subtext="Kafka, Low-code platform, Camunda, Alfresco,
NGINX, Apache, Apache Tomcat, Spring
Framework, Spark Framework, HAProxy,
JBoss/Wildfly, GlassFish, Apache ActiveMQ,
Apache Camel, ZooKeeper, Zanata, Pentaho,
OpenCms, 1C, Visiology, Apache Superset,
Metabase"
          />
          <RoadmapItem
            addToRefs={addToRefs}
            title="Решения для мониторинга и тестирования"
            subtext="Zabbix, Prometeus, Graphite, Grafana, ELK
(Elasticsearch, Logstash, Kibana), JUnit, Selenium,
Yandex Allure, JMeter, Yandex.Tank, Jaeger, Sentry"
          />
          <RoadmapItem
            addToRefs={addToRefs}
            title="Базы данных, хранилища (реляционные и noSQL)"
            subtext="PostgreSQL, MySQL, Oracle, Microsoft SQL Server,
Azure SQL, Amazon RDS, MongoDB, CouchDB,
Cassandra, Redis, DynamoDB, Clickhouse,
InfluxDB, TimescaleDB, HBase"
          />
          
        </Items>
      </Container>
    </Section>
  );
};

//Single Roadmap Item
const RoadmapItem = ({ title, subtext, addToRef }) => {
  return (
    <Item ref={addToRef}>
      <ItemContainer>
        <Box>
          <SubTitle>{title}</SubTitle>
          <Text>{subtext}</Text>
        </Box>
      </ItemContainer>
    </Item>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  font-weight: 500;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 70%;
  height: 200vh;
  background-color: ${(props) => props.theme.body};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
  }
`;

const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Items = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 90%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;

    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 20px 20px 20px 20px;
      text-align: right;
      border-color: #095c68;

      @media (max-width: 48em) {
        border-radius: 0 50px 0 50px;
        text-align: left;

        p {
          border-radius: 20px 20px 20px 20px;
        }
      }
    }
    p {
      border-radius: 20px 20px 20px 20px;
    }
  }

  & > *:nth-of-type(2n) {
    justify-content: end;

    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 20px 20px 20px 20px;
      text-align: left;
      border-color: #095c68;
    }

    p {
      border-radius: 20px 20px 20px 20px;
    }
  }
`;

const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 48em) {
    justify-content: flex-end !important;
  }
`;

const ItemContainer = styled.div`
  width: 40%;
  height: fit-content;
  padding: 1rem;
  border: 3px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 70%;
  }
`;

const Box = styled.p`
  height: fit-content;
  background-color: ${(props) => props.theme.carouselColor};
  color: ${(props) => props.theme.text};
  padding: 1rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.text};
`;

const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontsm};
  font-weight: 400;
  margin: 0.5rem 0rem;
  text-transform: capitalize;
  color: ${(props) => props.theme.text};

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

export default Roadmap;
