import React from "react";
import styled from "styled-components";
import Android from "../../assets/androidQR.png"
import Apple from "../../assets/appleQR.png"
//import Carousel from "../Carousel";
//import Button from "../Button";
//import { Dark } from "../../styles/Themes";

const About = () => {
  return (
    <Section id="about">
      <Container>
        <Box>
          <Title>
          Наша команда
          </Title>
        <SubText>
        У нас работает более 30 талантливых ИТ-специалистов c профильным образованием. В своей работе мы
придерживаемся правил – Срочность запуска, гибкость реализации, удобство взаимодействия, оптимизация расходов и
прозрачность хода проекта.
          </SubText>
          <SubTextLight>
          Мы основали нашу компанию в 2018 году. Мы специализируемся на разработке программного обеспечения в сфере ЖКХ. 
Мы разработали онлайн систему управления недвижимостью “УправБот”.
За время работы над проектом, мы накопили богатый опыт взаимодействия с системами с большими массивами данных, строгой финансовой отчетности, а также с системами с повышенными требования к скорости обмена данными.
Наши продукты используют управляющие, сервисные и обслуживающие компании, поставщики услуг, страховщики недвижимости.
Мы предоставляем широкий спектр услуг по сопровождению и реализации IT-проектов от идеи до релиза.
Наша команда подберет оптимальное для вас решение под ваши задачи и бюджет.
Мы найдем вариант сотрудничества, который поможет вам воспользоваться преимуществами внешней экспертизы.
          </SubTextLight>
          <QRcode>     
          <a href="https://play.google.com/store/apps/details?id=ru.matorinun.matorin">
          <img  src={Android} width="200" height="200" alt="Приложение для Android"></img>
          </a>     
          <a href="https://apps.apple.com/ru/app/%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%B1%D0%BE%D1%82/id1389783867">
          <img src={Apple} width="200" height="200" alt="Приложение для Android"></img>
          </a>
          </QRcode>
          {/* <Carousel /> */}
        </Box>
        <Box>
          <Title>
          Реализуйте задуманное с
          нашей командой специалистов.
          </Title>
          <SubText>
          Выделенная команда под проект — это
профессионалы в области управления проектами, 
frontend и backend разработки, DevOps, 
тестирования и UX/UI дизайна.
          </SubText>
          <SubTextLight>
          Наша команда подберет для Вас решение под размер и бюджет вашего проекта.
Наша специализация: Мобильная разработка, web-разработка порталов и сервисов, ИТ-разработка под ключ, 
автоматизация бизнес процессов.
Мы найдем варианты сотрудничества, который поможет вам воспользоваться преимуществами внешней экспертизы.
          </SubTextLight>
          {/* <ButtonContainer>
            <ThemeProvider theme={Dark}>
              <Button text="Join Our Discord" link="#" />
            </ThemeProvider>
          </ButtonContainer> */}
        </Box>
      </Container>
    </Section>
  );
};

const Section = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const QRcode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 50px;
`;


const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media (max-width: 64em) {
    & > *:last-child {
      width: 90%;
    }
  }
`;

const Box = styled.div`
  width: 50%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {
    min-height: 50vh;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  margin: 1rem auto;
  width: 80%;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.bodyRgba}, 0.6)`};
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

// const ButtonContainer = styled.div`
//   width: 80%;
//   display: flex;
//   align-self: flex-start;
//   margin: 1rem auto;

//   @media (max-width: 64em) {
//     width: 100%;

//     button {
//       margin: 0 auto;
//     }
//   }
// `;

export default About;
