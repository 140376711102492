import styled from "styled-components";
// import ConfettiComponent from "../Confetti";

// import img1 from "../../assets/Nfts/bighead.svg";
// import img2 from "../../assets/Nfts/bighead-1.svg";
// import img3 from "../../assets/Nfts/bighead-2.svg";
// import img4 from "../../assets/Nfts/bighead-3.svg";
// import img5 from "../../assets/Nfts/bighead-4.svg";
// import img6 from "../../assets/Nfts/bighead-5.svg";
// import img7 from "../../assets/Nfts/bighead-6.svg";
// import img8 from "../../assets/Nfts/bighead-7.svg";
// import img9 from "../../assets/Nfts/bighead-8.svg";

const Team = () => {
  return (
    <Section id="team">
      {/* <ConfettiComponent /> */}
      <Title>Контакты</Title>
      <Container>
      <SubText>Адрес: 119071, г. Москва, ул. Малый Калужский переулок, д. 15, стр. 16
      <br/>
ИНН: 7725498650
      <br/>
Телефон: +7(495)772-56-36
<br/>
Email: <a href="mailto:sales@provercast.ru">sales@provercast.ru</a></SubText>
        {/* <MemberComponent img={img1} name="Skyblaze" position="Founder" />
        <MemberComponent img={img2} name="Megnum" position="Co Founder" />
        <MemberComponent img={img3} name="Monkey King" position="Director" />
        <MemberComponent img={img4} name="Black Panther" position="Manager" />
        <MemberComponent img={img5} name="Deathstroke" position="Artist" /> 
        <MemberComponent
          img={img6}
          name="Lazy Kong"
          position="Social Media Manager"
        />
        <MemberComponent
          img={img7}
          name="Cyber Punk"
          position="Blockchain Specialist"
        />
        <MemberComponent img={img8} name="Monk" position="Web3 Developer" />
        <MemberComponent img={img9} name="Banana" position="Graphic Designer" />*/}
      </Container>
    </Section>
  );
};

// const MemberComponent = ({ img, name = " ", position = " " }) => {
//   return (
//     <Item>
//       <ImgContainer>
//         <img src={img} alt={name} />
//       </ImgContainer>
//       <Name>{name}</Name>
//       <Position>{position}</Position>
//     </Item>
//   );
// };

const Section = styled.section`
  height: 27vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  font-weight: 500;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
`;

// const Item = styled.div`
//   width: calc(20rem - 4vw);
//   padding: 1rem 0;
//   color: ${(props) => props.theme.body};
//   margin: 2rem 1rem;
//   position: relative;
//   border: 2px solid ${(props) => props.theme.text};
//   border-radius: 20px;
//   backdrop-filter: blur(4px);
//   z-index: 5;

//   &:hover {
//     img {
//       transform: translateY(-1rem) scale(1.2);
//     }
//   }

//   @media (max-width: 30em) {
//     width: 70vw;
//   }
// `;

// const ImgContainer = styled.div`
//   width: 80%;
//   margin: 0 auto;
//   background-color: ${(props) => props.theme.carouselColor};
//   border: 1px solid ${(props) => props.theme.text};
//   border-radius: 20px;
//   padding: 1rem;
//   cursor: pointer;

//   img {
//     width: 100%;
//     height: auto;
//     transition: all 0.3s ease;
//   }
// `;

// const Name = styled.h2`
//   font-size: ${(props) => props.theme.fontlg};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-transform: uppercase;
//   color: ${(props) => props.theme.text};
//   margin-top: 1rem;
// `;

// const Position = styled.h2`
//   font-size: ${(props) => props.theme.fontmd};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-transform: capitalize;
//   color: ${(props) => `rgba(${props.theme.textRgba}, 0.9)`};
//   font-weight: 400;
// `;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: #095c68;
  align-self: flex-start;
  margin: 1rem auto;
  width: 80%;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

// const SubTextLight = styled.p`
//   font-size: ${(props) => props.theme.fontmd};
//   text-transform: capitalize;
//   color: ${(props) => `rgba(${props.theme.bodyRgba}, 0.6)`};
//   align-self: flex-start;
//   width: 80%;
//   margin: 1rem auto;
//   font-weight: 400;

//   @media (max-width: 64em) {
//     width: 100%;
//     text-align: center;
//     font-size: ${(props) => props.theme.fontsm};
//   }

//   @media (max-width: 40em) {
//     font-size: ${(props) => props.theme.fontsm};
//   }

//   @media (max-width: 30em) {
//     font-size: ${(props) => props.theme.fontxs};
//   }
// `;

export default Team;
